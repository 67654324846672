import React, { useEffect, useState } from 'react';

import { useQueryParam, StringParam } from 'use-query-params';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import api from '../../services/api';

import { Container, Grid } from './styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Search() {
  const classes = useStyles();
  const [tabelas, setTabelas] = useState({
    tipo: [],
    idade: [],
    porte: [],
  });
  const [ongs, setOngs] = useState([]);
  const [form, setForm] = useState({
    tipo: '',
    idade: '',
    porte: '',
    sexo: '',
  });
  const [, setType] = useQueryParam('type', StringParam);
  const [, setAge] = useQueryParam('age', StringParam);
  const [, setPort] = useQueryParam('port', StringParam);
  const [, setSexo] = useQueryParam('sexo', StringParam);
  const [, setOng] = useQueryParam('ong', StringParam);

  useEffect(() => {
    api.get('tabs/ongs').then(response => {
      setOngs(response.data);
    });

    api.get('tabelas?except=tab_raca').then(response => {
      const percorretipo = response.data.filter(
        item => item.tipo === 'tab_tipo',
      );
      const percorreidade = response.data.filter(
        item => item.tipo === 'tab_idade',
      );
      const percorreporte = response.data.filter(
        item => item.tipo === 'tab_porte',
      );

      setTabelas({
        tipo: percorretipo,
        idade: percorreidade,
        porte: percorreporte,
      });
    });
  }, []);

  return (
    <Container>
      <Grid>
        <div className="form">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Escolher Ong</InputLabel>
            <Select
              native
              value={form.ong}
              onChange={e => {
                setForm({ ...form, ong: e.target.value });
                setOng(e.target.value);
              }}
              label="Escolher Ong"
              name="ong"
            >
              <option aria-label="None" value="" />
              {ongs.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="form">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Tipo</InputLabel>
            <Select
              native
              value={form.tipo}
              onChange={e => {
                setForm({ ...form, tipo: e.target.value });
                setType(e.target.value);
              }}
              label="Tipo"
              name="tipo"
            >
              <option aria-label="None" value="" />
              {tabelas.tipo.map(item => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="form">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Idade</InputLabel>
            <Select
              native
              value={form.idade}
              onChange={e => {
                setForm({ ...form, idade: e.target.value });
                setAge(e.target.value);
              }}
              label="Idade"
            >
              <option aria-label="None" value="" />
              {tabelas.idade.map(item => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="form">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Porte</InputLabel>
            <Select
              native
              value={form.porte}
              onChange={e => {
                setForm({ ...form, porte: e.target.value });
                setPort(e.target.value);
              }}
              label="Porte"
              inputProps={{
                name: 'porte',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {tabelas.porte.map(item => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="form">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Sexo</InputLabel>
            <Select
              native
              value={form.sexo}
              onChange={e => {
                setForm({ ...form, sexo: e.target.value });
                setSexo(e.target.value);
              }}
              label="Sexo"
              inputProps={{
                name: 'sexo',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option value="macho">Macho</option>
              <option value="femea">Fêmea</option>
            </Select>
          </FormControl>
        </div>
      </Grid>
    </Container>
  );
}
