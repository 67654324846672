import React from 'react';

import logoOne from '../../images/logos/01.jpg';
import logoTwo from '../../images/logos/02.jpg';
import logoThree from '../../images/logos/03.jpg';
import logoFour from '../../images/logos/04.jpg';
import logoFive from '../../images/logos/07.jpg';
import logoSix from '../../images/logos/06.jpg';

import * as S from './styles';

export default function Support() {
  return (
    <S.Container>
      <S.Grid>
        <div className="titleandview">
          <h4>Um serviço:</h4>
          <img src={logoSix} alt="" />
        </div>
        <div className="titleandview">
          <h4>Mantenedor:</h4>
          <img src={logoFive} alt="" />
        </div>
        <div className="titleandview">
          <h4>Apoio:</h4>
          <img src={logoOne} alt="" />
        </div>
        <div className="titleandview">
          <h4 style={{ color: '#ccc' }}>.</h4>
          <img src={logoTwo} alt="" />
        </div>
        <div className="titleandview">
          <h4 style={{ color: '#ccc' }}>.</h4>
          <img src={logoThree} alt="" />
        </div>
        <div className="titleandview">
          <h4 style={{ color: '#ccc' }}>.</h4>
          <img src={logoFour} alt="" />
        </div>
      </S.Grid>
    </S.Container>
  );
}
