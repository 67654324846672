import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 8px;
  background: none;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding-left: 8px;
  padding-right: 8px;

  .form + .form {
    padding-left: 0px;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .form {
    width: 100%;

    div {
      width: 100%;
    }

    select {
      width: 100%;
      background: #fff;
    }
  }

  @media only screen and (min-width: 481px) {
    flex-direction: column;

    .form + .form {
      padding-left: 0px;
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;

    .form + .form {
      padding-left: 6px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  @media only screen and (min-width: 1280px) {
    flex-direction: row;

    .form + .form {
      padding-left: 6px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`;
