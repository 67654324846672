import React, { useEffect, useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useQueryParam, StringParam } from 'use-query-params';

import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Popover from '@material-ui/core/Popover';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';

import { MdStar } from 'react-icons/md';

import { Link, navigate } from 'gatsby';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative } from 'date-fns';
import injecao from '../../../../images/icones/injection-needle.svg';
import veterinario from '../../../../images/icones/veterinary.svg';
import medicamento from '../../../../images/icones/pill.svg';
import chipadoIcon from '../../../../images/icones/memory.svg';

import { updateFavoriteRequest } from '../../../../store/modules/user/actions';
import api from '../../../../services/api';
import LoadingCard from '../../../Loading/Card';

import avatar from '../../../../images/semavatar.jpg';

import { Conteudo, BtnCarrega, Icones } from './styles';
import { BASE } from '../../../../utils/environament';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500], // eslint-disable-line
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const favorito = useSelector(state => state.user.favorite);
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(true);
  const [loadingFooter, setLoadingFooter] = useState(false);
  const [pets, setPets] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [type] = useQueryParam('type', StringParam);
  const [age] = useQueryParam('age', StringParam);
  const [port] = useQueryParam('port', StringParam);
  const [sexo] = useQueryParam('sexo', StringParam);
  const [ong] = useQueryParam('ong', StringParam);
  const [state, setState] = useState({
    limit: 12,
    page: 1,
  });

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleLike = useCallback(
    async e => {
      if (!profile) {
        navigate('/login');
        return;
      }

      if (!e.like) {
        const resLike = await api.post('likes', {
          pet_id: e.id,
        });

        const percorreLike = pets.map(item => ({
          ...item,
          like: item.id === e.id ? true : item.like,
          like_id: item.id === e.id ? resLike.data.id : item.like_id,
        }));

        setPets(percorreLike);
        return;
      }

      await api.delete(`likes/${e.like_id}`);

      const percorreLike = pets.map(item => ({
        ...item,
        like: item.id === e.id ? false : item.like,
        like_id: item.id === e.id ? null : item.like_id,
      }));

      setPets(percorreLike);
    },
    [pets],
  );

  const handleFavorite = useCallback(
    async e => {
      if (!profile) {
        navigate('/login');
        return;
      }

      if (!e.favourite) {
        const response = await api.post('favourite', {
          pet_id: e.id,
        });

        const percorreFaourite = pets.map(item => ({
          ...item,
          favourite: item.id === e.id ? true : item.favourite,
          favourite_id: item.id === e.id ? response.data.id : item.favourite_id,
        }));

        setPets(percorreFaourite);
        dispatch(updateFavoriteRequest(favorito + 1));
        return;
      }

      await api.delete(`favourite/${e.favourite_id}`);

      const percorreFavourite = pets.map(item => ({
        ...item,
        favourite: item.id === e.id ? false : item.favourite,
        favourite_id: item.id === e.id ? null : item.favourite_id,
      }));

      setPets(percorreFavourite);
      dispatch(updateFavoriteRequest(favorito - 1));
    },
    [pets, dispatch],
  );

  const handlePage = useCallback(async () => {
    const page = state.page + 1;
    setLoadingFooter(true);
    const response = await api.get(`dashboard/pet`, {
      params: {
        user: profile ? profile.id : '',
        type,
        age,
        port,
        sexo,
        ong,
        page,
        limit: '12',
      },
    });

    const percorre = response.data.data.map(item => ({
      ...item,
      dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
        locale: pt,
      }),
    }));

    setPets(petsState => [...petsState, ...percorre]);
    setState({ ...state, page });
    setLoadingFooter(false);
  }, [state]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`dashboard/pet`, {
        params: {
          user: profile ? profile.id : '',
          type,
          age,
          port,
          sexo,
          ong,
          limit: '12',
        },
      })
      .then(response => {
        const percorre = response.data.data.map(item => ({
          ...item,
          dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
            locale: pt,
          }),
        }));

        setLoading(false);
        setPets(percorre);
        setLastPage(response.data.last_page);
      });
  }, [type, age, port, sexo, ong]);

  return (
    <>
      <Conteudo>
        <div className="title">
          <span />
          <h4>Lista de Pets</h4>
          <span />
        </div>
        <div id="container-grid">
          {loading ? (
            <LoadingCard string="12345678" />
          ) : (
            <>
              {pets.map(item => (
                <Card key={item.id} className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        {item.user.file ? (
                          <img
                            src={`${BASE.API}/storage/${item.user.file.file}`}
                            alt={item.user.name}
                            style={{ width: '100%' }}
                          />
                        ) : (
                          <img
                            src={avatar}
                            alt={item.user.name}
                            style={{ width: '100%' }}
                          />
                        )}
                      </Avatar>
                    }
                    title={item.user.name}
                    subheader={`Postado: ${item.dateFormatted}`}
                  />
                  <Link to={`/app/pet/${item.slug}`}>
                    <CardMedia
                      className={classes.media}
                      image={
                        item.file ? `${BASE.API}/storage/${item.file.file}` : ''
                      }
                      title={item.name}
                    />
                  </Link>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Nome do pet: {item.name}
                      <Icones>
                        {item.heart.find(i => i === 'vacinado') && (
                          <img src={injecao} alt="vacinado" title="Vacinado" />
                        )}
                        {item.heart.find(i => i === 'castrado') && (
                          <img
                            src={veterinario}
                            alt="veterinário"
                            title="Veterinário"
                          />
                        )}
                        {item.heart.find(i => i === 'vermifugado') && (
                          <img
                            src={medicamento}
                            alt="medicamento"
                            title="Medicamento"
                          />
                        )}
                        {item.heart.find(i => i === 'chipado') && (
                          <img
                            src={chipadoIcon}
                            alt="chipado"
                            title="Chipado"
                          />
                        )}
                      </Icones>
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <IconButton
                      aria-label="like"
                      onClick={() =>
                        handleLike({
                          id: item.id,
                          like: item.like,
                          like_id: item.like_id,
                        })
                      }
                    >
                      <FavoriteIcon
                        color={item.like ? 'secondary' : 'disabled'}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() =>
                        handleFavorite({
                          id: item.id,
                          favourite: item.favourite,
                          favourite_id: item.favourite_id,
                        })
                      }
                    >
                      <MdStar color={item.favourite ? '#FF925E' : '#999'} />
                    </IconButton>
                    <IconButton aria-label="share" onClick={handleClick}>
                      <ShareIcon />
                    </IconButton>
                  </CardActions>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div className="shared">
                      <FacebookShareButton
                        url={`${BASE.API}/app/pet/${item.slug}`}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        url={`${BASE.API}/app/pet/${item.slug}`}
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={`${BASE.API}/app/pet/${item.slug}`}
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </div>
                  </Popover>
                </Card>
              ))}
            </>
          )}
          {loadingFooter && <LoadingCard string="12345678" />}
        </div>
        {lastPage !== state.page && (
          <BtnCarrega onClick={handlePage}>Carregar mais ...</BtnCarrega>
        )}
      </Conteudo>
    </>
  );
};

export default Dashboard;
