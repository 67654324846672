import React, { useEffect, useState } from 'react';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import Layout from '../components/Layout'; // eslint-disable-line
import SEO from '../components/seo'; // eslint-disable-line
import CardPet from '../components/pages/Pet/Dashboard';
import Search from '../components/Search';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Support from '../components/Support';
import api from '../services/api';

import 'react-awesome-slider/dist/styles.css';

import { store, persistor } from '../store';

import { View, Container, Anuncio } from '../components/pages/styles';

import { BASE } from '../utils/environament';

const url = `${BASE.API}/storage/`;

const Index = () => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    async function load() {
      const response = await api.get('files?subtype=banners');

      setFiles(response.data.data);
    }

    load();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
          <SEO title="Home" />
          <Header />
          <View>
            <Search />

            <Container>
              <Anuncio>
                <Fade duration={8000}>
                  {files.map(file => (
                    <div key={`file-${file.id}`} className="each-fade">
                      <div className="image-container">
                        {file.link ? (
                          <a href={file.link} target="blank">
                            <img
                              src={`${url}/${file.file}`}
                              alt="Cadastro pet"
                            />
                          </a>
                        ) : (
                          <img src={`${url}/${file.file}`} alt="Cadastro pet" />
                        )}
                      </div>
                    </div>
                  ))}
                </Fade>
              </Anuncio>
              <CardPet />

              <Support />
            </Container>
          </View>

          <Footer />
        </Layout>
      </PersistGate>
    </Provider>
  );
};

export default Index;
