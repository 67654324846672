import styled from 'styled-components';
import { shade } from 'polished';

export const Conteudo = styled.div`
  display: block;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 60px;

  .MuiCardHeader-subheader {
    font-size: 11px;
  }

  .shared {
    display: block;
    padding: 8px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    span {
      display: flex;
      flex: 1;
      border: 2px dotted #999;
    }

    h4 {
      font-size: 28px;
      margin-left: 8px;
      margin-right: 8px;
      color: #999;
    }
  }

  .img-avatar {
    width: 100%;
  }

  #container-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
  }

  @media only screen and (min-width: 430px) {
    #container-grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (min-width: 481px) {
    #container-grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (min-width: 1024px) {
    #container-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  @media only screen and (min-width: 1280px) {
    #container-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`;

export const BtnCarrega = styled.button`
  display: flex;
  border: 0;
  width: 100%;
  border-radius: 4px;
  background: #404040;
  color: #fff;
  justify-content: center;
  padding: 10px 0;
  transition: background-color 0.2s;
  margin-top: 10px;

  &:hover {
    background: ${shade(0.3, '#404040')};
  }
`;

export const Icones = styled.div`
  display: block;
  width: 100%;
  padding-top: 12px;

  img + img {
    margin-left: 12px;
  }

  img {
    width: 20px;
  }
`;
