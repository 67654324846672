import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .titleandview {
    display: block;

    + .titleandview {
      margin-left: 8px;
    }

    h4 {
      margin-bottom: 8px;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;

  @media only screen and (min-width: 481px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  img {
    width: 100%;
  }
`;
